import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Abbey({ styles, ...props }) {
  const Elements = useComponents()
  const background_color = styles.forElement("background").backgroundColor
  const checkboxes_color = styles.forElement("content_text").color
  const border_radius = styles.forElement("field").borderRadius

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className="formkit-background"
        style={{ backgroundColor: background_color }}
      />
      <div
        className="formkit-background"
        style={styles.forElement("background")}
      />
      <div className="formkit-container">
        <header>
          <Elements.Image className="formkit-image" name="logo" />
        </header>
        <div className="formkit-main">
          <div>
            <Elements.Heading
              className="formkit-heading-primary"
              name="header"
              defaults={{
                content: "Catchy headline goes here."
              }}
            />
            <Elements.Region name="content" className="formkit-content">
              <Elements.Content
                defaults={{
                  content:
                    "Provide some more detail in this content area. Vestibulum ut ornare lobortis fermentum a consectetur etiam adipiscing litora consectetur dis lorem parturient himenaeos at ridiculus duis at a ad ridiculus parturient ipsum enim lorem scelerisque."
                }}
              />
            </Elements.Region>
          </div>
          <div className="formkit-card">
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields
                style={{
                  color: checkboxes_color,
                  borderRadius: border_radius
                }}
              >
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
        </div>
      </div>
      <Elements.BuiltWith background="background" image="background" />
    </Elements.LandingPage>
  )
}

Abbey.style = "landing_page"
Abbey.categories = ["Event", "Newsletter", "Profile", "Waitlist"]
Abbey.thumbnail = ""
Abbey.preview = "https://demo.ck.page/abbey"
Abbey.fields = [
  {
    name: "first_name",
    label: "Your name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Abbey, { name: "Abbey" })
